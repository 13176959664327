import { Box, Container, Link, Typography } from "@mui/material";
import * as React from "react";
import { Link as RouterLink } from 'react-router-dom';

export default function GooglePlayRequest() {
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Help us make the app available on Google Play!
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        We have already built the first version of the app for Android but Google Play requires at least 20 people to test it for 14 days in order to have it published on the Google Play Store. If you
                        would like access to the Android version of the app please email <Link component={RouterLink} to="mailto:info@mapmymoles.com">info@mapmymoles.com</Link> and we'll add you to the test - all you need to do is download the app, try it, and ideally give some feedback
                        on what works / doesn't work. Your help would be greatly appreciated!
                    </Typography>
                </Container>
            </Container>
        </Box>
    );
}