import { Box, Container, Typography } from "@mui/material";
import * as React from "react";
import googlePlayBadge from '../assets/google-play-badge.png';
import { ReactComponent as AppStoreSvg } from '../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

export default function MobileApps() {
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Download the free app today
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        The mobile app was created using expo and is available free of charge, with no in-app purchases or subscriptions, on both Google Play for Android devices and the App Store for iOS devices.
                    </Typography>
                    <Box maxWidth="100%" display="flex" alignItems="center">
                        <a href='/googleplayrequest'>
                            {/* <a href='https://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank' rel='noreferrer'> */}
                            <img alt='Get it on Google Play' src={googlePlayBadge} height={70} />
                        </a>
                        <a href='https://apps.apple.com/us/app/map-my-moles/id6476600349?platform=iphone' target='_blank' rel='noreferrer'>
                            <AppStoreSvg />
                        </a>
                    </Box>
                </Container>
            </Container>
        </Box>
    );
}