import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container } from "@mui/material";

export default function Faqs() {
    return (
        <Box height="100%" width="100%">
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                    {
                        [
                            { question: "Is the app free to use?", answer: "Yes, the app is free to download from either the Google Play Store or the Apple App Store. There are no in-app purchases, no subscriptions, and no premium features that cost anything extra." },
                            { question: "Are my photos backed up anywhere?", answer: "No, any photos that you take using the app or add into the app from your library exist only in the app. Once deleted, they're gone. As privacy is important to us, there is no mechanism by which users can upload photos online to any of our systems. However, it is possible for you to create a personal back-up using the share functionality. See the how to / guidance page for more details." },
                            { question: "Can I suggest an improvement to the app?", answer: "Yes, please go to the contact us page to get in touch with any feedback / suggestions." },
                            { question: "How do I use the web app?", answer: "The web app is designed to allow you to view your images on a larger screen (i.e. a laptop/desktop computer) and make comparisons between dates for chosen parts of your body. You will need to create a zipped folder using the share button in the app and send that zipped folder to your computer (e.g. via email) so that it can then be accessed using the web app. Part of the intention of this is for you to be able to either send your profile to a dermatologist or put it on a portable storage device so that they can analyse your historical photos." },
                            //{ question: "question", answer: "answer" },
                        ].map(({ question, answer }, num) => (
                            <Accordion key={num} sx={{ width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    {num + 1}. {question}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {answer}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Container>
            </Container>
        </Box>
    );
}