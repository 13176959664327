import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import NotFound from './pages/notfound';
import About from './pages/about';
import Privacy from './pages/privacy';
import Faqs from './pages/faqs';
import Contact from './pages/contact';
import MobileApps from './pages/mobileapps';
import Guide from './pages/guide';
import WebApp from './pages/webapp';
import GooglePlayRequest from './pages/googleplayrequest';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="faqs" element={<Faqs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="mobileapps" element={<MobileApps />} />
          <Route path="guide" element={<Guide />} />
          <Route path="webapp" element={<WebApp />} />
          <Route path="googleplayrequest" element={<GooglePlayRequest />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
