import * as React from 'react';
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Container, Stack, Typography } from "@mui/material";
import { Context } from "../utils/context";

export default function Guide() {
    const { darkMode } = React.useContext(Context);
    return (
        <Box height="100%" width="100%">
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: "column", alignItems: "center" }}>
                    {
                        [
                            {
                                title: "First time opening the app",
                                steps: [
                                    { image: "settings-newprofile", label: "The first time you open the app you will be prompted to choose a profile name. You can have more than one profile on the app (e.g. to keep track of yourself and a family member). See instructions on adding a new profile in the settings to add more profiles." },
                                ]
                            },
                            {
                                title: "Add a single photo",
                                steps: [
                                    { image: "analyse-home", label: "From the home page, navigate to the 'New photo' tab." },
                                    { image: "newphoto-home", label: "Select the area of the body of which you are taking / selecting a photo and then tap either the 'Use camera' button or the 'Choose from library' button. This will open the camera / image library on your phone, where you can take / select a picture." },
                                    { image: "newphoto-details", label: "Crop your image to size, add any notes you wish to add, select the date of the photo, and then tap 'Save photo'." },
                                ]
                            },
                            {
                                title: "Add many photos (all overdue / scheduled photos)",
                                steps: [
                                    { image: "analyse-home", label: "From the home page, navigate to the 'New photo' tab." },
                                    { image: "newphoto-home", label: "Tap the 'Work through all scheduled photos' button at the top. If you have 'every 3 months' chosen in the settings, then the scheduled photos are all of those which do not have a photo in the last 3 months." },
                                    { image: "newphoto-scheduled", label: "At this point, it will display the latest image of the current area for which you have overdue photos. Tap either 'Use camera' or 'Choose from library' to add a new photo, or 'Skip' ahead if you do not want to update this particular photo." },
                                    { image: "newphoto-details", label: "Crop your image to size, add any notes you wish to add, select the date of the photo, and then tap 'Save photo'. After this, it will return to the previous screen, ready to work on the next scheduled photo." },
                                ]
                            },
                            {
                                title: "Analyse your photos",
                                steps: [
                                    { image: "analyse-home", label: "From here, tap the area of the body which you wish to analyse (imagine the body is facing towards you in this case)." },
                                    { image: "analyse-sections", label: "The next screen displays more details about the sections in that area of the body. Tap the section you wish to analyse." },
                                    { image: "analyse-compare", label: "You then have a side-by-side view of all of the photos you've taken of this section / individual mole. You can progress forwards / backwards, zoom in and out, rotate the images so that they are aligned if needed, and access a variety of other options using the 3-dot menu. Note: if only a single photo has been taken, it will display the same photo above and below." },
                                ]
                            },
                            {
                                title: "Add / mark an individual mole",
                                steps: [
                                    { image: "analyse-home", label: "Start by tapping the area of the body in which the individual mole is located." },
                                    { image: "analyse-sections", label: "Then tap the section in which it is located (you must already have a photo containing the individual - if not, see instructions to add a photo)." },
                                    { image: "analyse-compare-new-individual", label: "From the comparison view, navigate to the photo to which you want to add / mark the individual, and open the 3-dot menu. Tap 'New individual'." },
                                    { image: "individual-details", label: "On the next screen you can drag and drop the location of the marker to pinpoint where the individual mole lies, and you can give it a name (individual mole names must be unique per profile). The tap 'Save individual' to save it." },
                                ]
                            },
                            {
                                title: "Check an individual mole's location",
                                steps: [
                                    { image: "analyse-home", label: "Start by tapping the area of the body in which the individual mole is located." },
                                    { image: "analyse-sections", label: "Then tap the 'Individual moles' button at the top (next to 'Body sections')" },
                                    { image: "analyse-sections-individuals", label: "This shows all of the individuals in the area, with information on the number of inidivudal photos taken and the last date. Tap and hold one of the individuals to show its location." },
                                    { image: "analyse-sections-showlocation", label: "The location of the individual pops up in a modal window so that you can eaily identify where it is on your body." },
                                ]
                            },
                            {
                                title: "Add a photo of an individual mole",
                                steps: [
                                    { image: "analyse-home", label: "This works in much the same way as adding any other photo. From the home page, navigate to the 'New photo' tab." },
                                    { image: "newphoto-home", label: "Tap the 'Individuals' button to show the individual moles (rather than larger body sections)" },
                                    { image: "newphoto-home-individual", label: "This time select the individual mole you are taking / selecting a photo of and then tap either the 'Use camera' button or the 'Choose from library' button. This will open the camera / image library on your phone, where you can take / select a picture." },
                                    { image: "newphoto-individual-details", label: "Crop your image to size, add any notes you wish to add, select the date of the photo, and then tap 'Save photo'." },
                                ]
                            },
                            {
                                title: "Show individual moles when analysing sections",
                                steps: [
                                    { image: "analyse-home", label: "From here, tap the area of the body which you wish to analyse (imagine the body is facing towards you in this case)." },
                                    { image: "analyse-sections", label: "The next screen displays more details about the sections in that area of the body. Tap the section you wish to analyse." },
                                    { image: "analyse-compare-show-individuals", label: "In the 3-dot menu, select the option to 'show individuals'." },
                                    { image: "analyse-compare-with-individuals", label: "Individual moles are then displayed and can be deleted / edited by tapping them and accessing the menu again." },
                                ]
                            },
                            {
                                title: "Delete a photo",
                                steps: [
                                    { image: "analyse-compare-show-individuals", label: "Follow the steps taken to analyse your photos, then open the 3-dot menu and choose 'delete photo'." },
                                    { image: "analyse-compare-delete-photo", label: "You will be prompted to confirm that you wish to delete this photo. Tap 'Yes' to confirm. It will also warn you that any individuals linked to this specific photo will also be deleted if the photo is deleted." },
                                ]
                            },
                            {
                                title: "Edit a photo's details",
                                steps: [
                                    { image: "analyse-compare-show-individuals", label: "Follow the steps taken to analyse your photos, then open the 3-dot menu and choose 'edit notes/date'." },
                                    { image: "edit-photo-details", label: "Here you can change the notes and recorded date of the photo, then save the changes by tapping 'Save photo'. The previously saved information for this photo will be overwritten. Any individuals linked to this photo will not be affected and will remain linked to this photo." },
                                ]
                            },
                            {
                                title: "Delete an individual mole",
                                steps: [
                                    { image: "analyse-compare-with-individuals", label: "Follow the steps taken to show individual moles when analysing photos. Individual moles can be selected by tapping them. After tapping the individual you wish to delete, tap the 3-dot menu." },
                                    { image: "analyse-compare-with-individuals-menu", label: "Tap 'delete individual' to delete the selected individual mole." },
                                    { image: "analyse-compare-delete-individual", label: "You will be prompted to confirm that you wish to delete this individual mole. Tap 'Yes' to confirm. It will also warn you that any photos of this individual mole will also be deleted." },
                                ]
                            },
                            {
                                title: "Edit an individual mole's details",
                                steps: [
                                    { image: "analyse-compare-with-individuals", label: "Follow the steps taken to show individual moles when analysing photos. Individual moles can be selected by tapping them. After tapping the individual you wish to edit, tap the 3-dot menu." },
                                    { image: "analyse-compare-with-individuals-menu", label: "Tap 'edit individual' to edit the selected individual mole." },
                                    { image: "edit-individual-details", label: "From here you can change the name and / or relocate the individual mole. Then tap 'Save individual' to save the changes." },
                                ]
                            },
                            {
                                title: "App information",
                                steps: [
                                    { image: "analyse-home", label: "Navigate to the 'Info' tab at the bottom of the screen." },
                                    { image: "info", label: "Here you can see some basic background information about the app as well as links to this website for more information." },
                                ]
                            },
                            {
                                title: "Edit profile settings",
                                steps: [
                                    { image: "settings", label: "Navigate to the 'Settings' tab at the bottom of the screen. Tapping the drop-down containing the currently active profile name allows you to change the active profile. Tap the 3-dot menu next to this to add / edit a profile name or delete a profile." },
                                    { image: "settings-profilemenu", label: "If you choose to 'Delete this profile' then all photos and individuals for the currently active profile will be deleted after a prompt to confirm your decision. Tapping 'Add a new profile' or 'Edit name' will bring up a dialog that allows you to enter the newly chosen name." },
                                    { image: "settings-newprofile", label: "Enter your chosen name and tap the button to confirm your choice." },
                                ]
                            },
                            {
                                title: "Change frequency of reminders",
                                steps: [
                                    { image: "settings", label: "Navigate to the 'Settings' tab at the bottom of the screen. Tap the pencil icon adjacent to 'Reminders' to change how often you want to take new photos." },
                                    { image: "settings-reminders", label: "Use the drop-downs to select your preferred frequency and then tap 'Save'. The default is every 3 months." },
                                ]
                            },
                            {
                                title: "Exclude sections / individual moles from reminders",
                                explanation: "Some people may have parts of their bodies which they do not need to keep track of - for example because there are no moles there at all. In other cases, an individual mole could be surgically removed and may no longer need to be tracked. This setting allows the user to opt-out of reminders for certain parts of the body.",
                                steps: [
                                    { image: "settings", label: "Navigate to the 'Settings' tab at the bottom of the screen. Tap the pencil icon adjacent to 'Exclude from reminders' to change which body parts you want excluded from reminders." },
                                    { image: "settings-reminderexclusions", label: "Tap and select the sections and / or individual moles which you wish to exclude from reminders and then tap 'Save'." },
                                ]
                            },
                            {
                                title: "Enable / disable notifications",
                                steps: [
                                    { image: "settings", label: "Navigate to the 'Settings' tab at the bottom of the screen. The bell icon can be tapped to either enable or disable push notifications. Tapping the calendar icon shows when the next push notifications are scheduled." },
                                    { image: "settings-upcomingreminders", label: "Here you can see when the next push notifications are due to be sent for each profile (if enabled)." },
                                ]
                            },
                            {
                                title: "Share photos externally or create a backup",
                                explanation: "This creates a zipped folder of your photos. To create a backup, you don't have to send this zipped folder to other people - you can send to yourself or add to your drive and treat this zipped folder as a backup of your data that can be used to restore your profiles in case anything is accidentally deleted (see instructions for importing profiles and / or restoring from a backup).",
                                steps: [
                                    { image: "settings", label: "Navigate to the 'Settings' tab at the bottom of the screen. Tap the 'Share external' button." },
                                    { image: "settings-shareprofiles", label: "Next you select which profiles to share. Tapping 'Share' after this will begin a process that creates a zipped folder containing all of your photos and information for the selected profiles and opens up a native sharing dialog for your phone that allows you to send the zipped folder by email, by WhatsApp, or by other means." },
                                ]
                            },
                            {
                                title: "View / import external profiles",
                                steps: [
                                    { image: "settings", label: "Navigate to the 'Settings' tab at the bottom of the screen. Tap the 'Import profile(s)' button. You will be prompted to selected a .zip file from somewhere in your local files directory. Knowing where your .zip folder is ahead of time can be helpful in this case." },
                                    { image: "settings-importorview", label: "Once you have selected the zipped folder, you will be asked whether you want to 'View only' or 'Save profiles'. Choosing 'Save profiles' will merge the external data with your existing data and you will be given choices in the event of any clashing profile names. Choosing 'View only' means the external data is only being viewed temporarily and will not be saved (useful if viewing a profile shared by friends / family)." },
                                    { image: "settings-viewonly", label: "Whilst in view-only mode there is a banner at the bottom of the app and some functionality is disabled (can't save / edit anything in view-only mode). To exit, tap the 'Exit view-only mode' button in the 'Settings' tab." },
                                ]
                            },
                            {
                                title: "Restore from a backup",
                                steps: [
                                    { image: "settings", label: "Navigate to the 'Settings' tab at the bottom of the screen. Tap the 'Restore from backup' button." },
                                    { image: "settings-restore", label: "You will be prompted to confirm that you wish to overwrite all existing photos and use the chosen .zip file instead. After choosing 'Yes, restore from backup' you will then choose the .zip file itself from your files in the same was as for importing a profile and existing data / photos will be deleted." },
                                ]
                            },
                        ].map(({ title, explanation, steps }, num) => (
                            <Accordion key={num} sx={{ width: '100%' }}>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    {num + 1}. {title}
                                </AccordionSummary>
                                <AccordionDetails sx={{ overflowX: "auto" }}>
                                    {explanation && <Typography sx={{ mb: 2 }}>{explanation}</Typography>}
                                    <Stack spacing={2} direction="row" alignItems="stretch">
                                        {
                                            steps.map(({ image, label }) => (
                                                <Card key={image} elevation={2} sx={{ minWidth: "300px", maxWidth: "300px" }} >
                                                    <CardContent>
                                                        <img height="600px" src={`/screenshots/${darkMode ? 'dark' : 'light'}-${image}.png`} alt={image} />
                                                        <Typography variant="body2">{label}</Typography>
                                                    </CardContent>
                                                </Card>
                                            ))
                                        }
                                    </Stack>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                </Container>
            </Container>
        </Box>
    );
}