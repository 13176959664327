import * as React from 'react';
import { AppBar, Box, Button, Container, CssBaseline, Drawer, Grid, IconButton, Link, Menu, MenuItem, ThemeProvider, Toolbar, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { darkTheme, lightTheme } from './theme/theme';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import logo from './assets/icon.png';
import googlePlayBadge from './assets/google-play-badge.png';
import { ReactComponent as AppStoreSvg } from './assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import { Context } from './utils/context';

const FULL_MENU = [
    {
        title: 'About',
        items: [
            { title: 'Map My Moles', navigationTo: '/about' },
            { title: 'Privacy / Data collection', navigationTo: '/privacy' },
            // { title: 'Terms & conditions', navigationTo: '/terms' },
            { title: 'FAQs', navigationTo: '/faqs' },
            { title: 'Contact us', navigationTo: '/contact' },
        ]
    },
    {
        title: 'Apps',
        items: [
            { title: 'How to / guidance', navigationTo: '/guide' },
            { title: 'Mobile apps', navigationTo: '/mobileapps' },
            { title: 'Web app', navigationTo: '/webapp' },
        ]
    },
];

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [darkMode, setDarkMode] = React.useState(prefersDarkMode);
    const [menuItems, setMenuItems] = React.useState([]);
    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(false);

    const theme = React.useMemo(() => darkMode ? darkTheme : lightTheme, [darkMode]);

    const largerThanMd = useMediaQuery("(min-width:600px");

    const menuButtons = FULL_MENU.map(
        ({ title, items }) => (
            <Button
                key={title}
                onClick={(event) => {
                    setMenuItems(items);
                    setAnchorElMenu(event.currentTarget);
                }}
                endIcon={<ArrowDropDownIcon />}
                sx={{ color: 'white' }}
            >
                {title}
            </Button>
        )
    );

    const value = { darkMode };

    return (
        <Context.Provider value={value}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Container maxWidth="lg">
                        <Toolbar maxWidth sx={{ minHeight: '64px' }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Button component={RouterLink} to="/" sx={{ textTransform: 'none' }}>
                                    <img src={logo} alt="logo" height="40px" style={{ marginRight: 10 }} />
                                    <Typography variant='h6' color="white">
                                        Map My Moles
                                    </Typography>
                                </Button>
                            </Box>
                            {largerThanMd ? menuButtons : ''}
                            <Tooltip title={`Switch to ${darkMode ? 'light' : 'dark'} mode`}>
                                <IconButton sx={{ mr: 2 }} onClick={() => setDarkMode(!darkMode)}>
                                    {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
                                </IconButton>
                            </Tooltip>
                            {
                                largerThanMd ?
                                    '' :
                                    <IconButton onClick={() => setMenuDrawerOpen(!menuDrawerOpen)} sx={{ color: 'white' }}>
                                        <MenuIcon />
                                    </IconButton>
                            }
                        </Toolbar>
                    </Container>
                </AppBar>
                <Menu
                    anchorEl={anchorElMenu}
                    open={Boolean(anchorElMenu)}
                    onClose={() => setAnchorElMenu(null)}
                    slotProps={{ paper: { style: { backgroundColor: darkMode ? theme.palette.background.paper : theme.palette.primary.main } } }}
                >
                    {
                        menuItems.map(
                            ({ title, navigationTo }) => (
                                <MenuItem key={title} component={RouterLink} to={navigationTo} onClick={() => setAnchorElMenu(null)}>
                                    <Typography variant='button' color="white">
                                        {title}
                                    </Typography>
                                </MenuItem>
                            )
                        )
                    }
                </Menu>
                <Drawer anchor="top" open={menuDrawerOpen && !largerThanMd} onClose={() => setMenuDrawerOpen(false)} PaperProps={{ style: { backgroundColor: darkMode ? theme.palette.background.paper : theme.palette.primary.main } }}>
                    <Toolbar sx={{ minHeight: '64px' }} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', padding: 1 }} alignItems="center">
                        {menuButtons}
                    </Box>
                </Drawer>
                <Box sx={{ display: 'flex', paddingTop: '64px' }}>
                    <Outlet />
                </Box>
                <Container maxWidth="100%" sx={{ backgroundColor: theme.palette.background.paper, padding: 5 }}>
                    <Container maxWidth="lg">
                        <Grid container>
                            <Grid item xs={12} md={4} container sx={{ mb: 2 }} spacing={2}>
                                <Grid item xs={6} md={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                        <img src={logo} alt="logo" height="80px" />
                                        <Typography variant="h6" sx={{ my: 2 }}>
                                            Map My Moles
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" justifyContent="flex-end">
                                        <a href='/googleplayrequest'>
                                            {/* <a href='https://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank' rel='noreferrer'> */}
                                            <img alt='Get it on Google Play' src={googlePlayBadge} height={62} />
                                        </a>
                                        <a href='https://apps.apple.com/us/app/map-my-moles/id6476600349?platform=iphone' target='_blank' rel='noreferrer'>
                                            <AppStoreSvg />
                                        </a>
                                    </Box>
                                </Grid>
                            </Grid>
                            {
                                FULL_MENU.map(({ title, items }) => (
                                    <Grid key={title} item xs={6} md={4}>
                                        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                                            <Typography variant='h6'>
                                                {title}
                                            </Typography>
                                            {
                                                items.map(
                                                    ({ title, navigationTo }) => (
                                                        <Link key={title} component={RouterLink} to={navigationTo} sx={{ mt: 3 }}>
                                                            <Typography>
                                                                {title}
                                                            </Typography>
                                                        </Link>
                                                    )
                                                )
                                            }
                                        </Box>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Container>
                </Container>
            </ThemeProvider>
        </Context.Provider>
    );
}

export default App;
