import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

export default function WebApp() {
    return (
        <Box height="100%" width="100%">
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Web App
                    </Typography>
                    <Typography sx={{ mb: 3 }}>
                        The web app is an accompaniment to the mobile apps. Through the web app, it is possible to view the exported zipped files from the
                        mobile apps on a larger screen for closer analysis. The intention is that a user can export and share their photos which can then be
                        easily viewed through the web app, ideally in the presence of a dermatologist who can more closely inspect the changes over time.
                    </Typography>
                    <Button href="https://app.mapmymoles.com" target="_blank" variant="contained" endIcon={<OpenInNew />} sx={{ mb: 3 }}>Launch web app</Button>
                </Container>
            </Container>
        </Box>
    );
}