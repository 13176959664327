import { Container, Typography } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';

export default function NotFound() {
    return (
        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center", height: '80vh' }}>
            <ErrorIcon fontSize="large" />
            <Typography variant="h4" sx={{margin: 2}}>404 - Page Not Found</Typography>
            <Typography textAlign="center">The page you are looking for does not exist or cannot be retrieved at this time</Typography>
        </Container>
    );
}