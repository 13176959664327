import { Box, Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

export default function About() {
    return (
        <Box height="100%" width="100%">
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Background
                    </Typography>
                    <Typography sx={{ mb: 5 }}>
                        This app was created as a hobby project. Personally, I have quite a lot of moles on my body and have had some removed in the past.
                        Regular checks with a dermatologist are important but I wanted something that I could use to keep track of things myself,
                        at home, and with zero cost, and where there's no risk of my photos being stored online somewhere in some cloud storage.
                    </Typography>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        How it works
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        You can take photos using your phone camera or you can choose photos from your phone's library. Either way, the pictures chosen are
                        stored locally on your device only within the app. You can specifiy the date of the picture and add any notes for observations you have made.
                        Then in the analyse section, you can compare how moles have changed over time with a convenient side-by-side view.
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        In addition, you can add pins to photos to keep a closer eye on specific individual moles, and add close-up photos of those individual moles also.
                        You can get reminders about new checks at a frequency of your choosing, and you can even package up all of your photos/information into a zipped
                        folder that can then be shared externally however you may choose (e.g. email, messaging apps, uploaded to Google drive etc.).
                    </Typography>
                    <Typography sx={{ mb: 5 }}>
                        For more information on how to use the app, check out the <Link component={RouterLink} to="/guide">how to / guidance</Link> page.
                    </Typography>
                </Container>
            </Container>
        </Box>
    );
}