import { Box, Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

export default function Privacy() {
    return (
        <Box height="100%" width="100%">
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Privacy Policy
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        We do not collect or use any of your personal data.
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        Your photos and information remain only on your personal device unless you choose to share them externally. For this reason,
                        if you accidentally delete the app, all of your data may be lost, so please take are and consider backing up your profiles
                        using the share button in the settings tab (more information in the <Link component={RouterLink} to="/guide">how to / guidance</Link>).
                    </Typography>
                </Container>
            </Container>
        </Box>
    );
}