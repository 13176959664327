import { Box, Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';

export default function Contact() {
    return (
        <Box height="100%" width="100%">
            <Container maxWidth="100%" sx={{ padding: 2 }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        Contact
                    </Typography>
                    <Typography sx={{ mb: 2 }}>
                        Please reach out to the following email address: <Link component={RouterLink} to="mailto:info@mapmymoles.com">info@mapmymoles.com</Link>. At the moment I'm just a one-man team working on this app as a hobby project so if it takes a while for me to get back to you, please be patient.
                    </Typography>
                </Container>
            </Container>
        </Box>
    );
}