import { Box, Card, CardContent, CardHeader, Container, Grid, Link, Typography, useTheme } from "@mui/material";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PlaceIcon from '@mui/icons-material/Place';
import SearchIcon from '@mui/icons-material/Search';
import LockIcon from '@mui/icons-material/Lock';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import ShareIcon from '@mui/icons-material/Share';
import * as React from "react";
import { Link as RouterLink } from "react-router-dom";
import googlePlayBadge from '../assets/google-play-badge.png';
import { ReactComponent as AppStoreSvg } from '../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';

export default function Home() {
    const theme = useTheme();
    const gradientBackground = `linear-gradient(0deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 30%, ${theme.palette.background.default} 70%, ${theme.palette.background.paper} 100%)`;
    const gradientBackgroundReversed = `linear-gradient(0deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 30%, ${theme.palette.background.paper} 70%, ${theme.palette.background.default} 100%)`;
    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Container maxWidth="100%" sx={{ padding: 2 }} style={{ background: gradientBackground }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Download the free app today
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        Melanoma is the deadliest form of skin cancer but if found and treated early, the survival rates are high.
                        Don't be lazy when it comes to your health. Simple checks can go a long way and make a difference. Track
                        your changes and go for regular check-ups with a dermatologist.
                    </Typography>
                    <Box maxWidth="100%" display="flex" alignItems="center">
                        <a href='/googleplayrequest'>
                            {/* <a href='https://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target='_blank' rel='noreferrer'> */}
                            <img alt='Get it on Google Play' src={googlePlayBadge} height={70} />
                        </a>
                        <a href='https://apps.apple.com/us/app/map-my-moles/id6476600349?platform=iphone' target='_blank' rel='noreferrer'>
                            <AppStoreSvg />
                        </a>
                    </Box>
                </Container>
            </Container>
            <Container maxWidth="100%">
                <Container maxWidth="lg" sx={{ padding: 3, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Features
                    </Typography>
                    <Grid container spacing={3} alignItems="stretch">
                        {
                            [
                                { icon: <PhotoCameraIcon />, title: "Take photos", subtitle: "Either take directly from your camera in the app or import photos from your library." },
                                { icon: <PlaceIcon />, title: "Map moles", subtitle: "Track individual moles specifically with close-up photos and recorded locations." },
                                { icon: <SearchIcon />, title: "Compare", subtitle: "Inspect changes over time for areas / individual moles." },
                                { icon: <LockIcon />, title: "Privacy", subtitle: "Your photos / data will not be stored online anywhere and will be for your personal use only." },
                                { icon: <CreditCardOffIcon />, title: "Free to use", subtitle: "This app will remain free to use for individuals. There is no paywall for advanced features - all features will be available to all users." },
                                { icon: <ShareIcon />, title: "Share", subtitle: "You can export your photos / data which can be used as a back-up, or shared with your dermatologist and viewed using the web app." },
                            ].map(
                                ({ icon, title, subtitle }) => (
                                    <Grid key={title} item xs={6} sm={4} md={3} lg={2}>
                                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                                            <CardHeader avatar={React.cloneElement(icon, { fontSize: "large", color: "primary" })} />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5">{title}</Typography>
                                                <Typography variant="body2">{subtitle}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )
                            )
                        }
                    </Grid>
                </Container>
            </Container>
            <Container maxWidth="100%" sx={{ minHeight: '50vh', display: 'flex', alignItems: 'center', padding: 3 }} style={{ background: gradientBackground }}>
                <Container maxWidth="md" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', height: '100%' }}>
                    <Typography variant="h4" sx={{ mb: 2 }}>
                        Testimonials
                    </Typography>
                    <Typography>
                        We are a new app / website but would love to hear any positive stories from your experiences of using the app that we could feature here
                        on our website. Please reach out to us <Link component={RouterLink} to="/contact">here</Link> to share your story.
                    </Typography>
                </Container>
            </Container>
            <Container maxWidth="100%" sx={{ minHeight: '25vh', display: 'flex', alignItems: 'center', padding: 3 }} style={{ background: theme.palette.background.default }}>
                <Container maxWidth="md" sx={{ textAlign: "center" }}>
                    <Typography>
                        Map My Moles is a personal project designed to make it easier for people to keep track of changes to marks and moles on their skin.
                        It does not offer any guidance or medical advice regarding the appearance of your personal marks / moles and should ideally be used in
                        collaboration with a qualified dermatologist who should be able to further analyse any observations you have made.
                    </Typography>
                </Container>
            </Container>
        </Box>
    )
}